import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { Link } from "gatsby"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
    text-align: center;
  }

  span {
    color: var(--primary);
  }

  p {
    line-height: 1.6em;
  }
`

const organisation_item = () => {
  return (
    <>
      <Seo title="Oświetlenie architektoniczne" />
      <Layout>
        <SimpleBanner title="Oświetlenie architektoniczne">
          <StaticImage
            className="banner__image"
            src="../../images/organisation/oswietlenie.jpg"
          />
        </SimpleBanner>
        <Container>
          <p>
            Każde, nawet najprostsze pomieszczenie zyskuje nowy charakter, a
            uroczystość nabiera niepowtarzalnego klimatu powodując zachwyt
            zaproszonych gości. Unikalne świetlne dekoracje na ślub uczynią tak
            ważny dzień naprawdę wyjątkowym, a sala weselna zyska nową jakość i
            niepowtarzalną atmosferę. Dekoracja świetlna możliwa jest w dowolnym
            wybranym przez Klienta kolorze jak i zestawieniu kilku kolorów
            jednocześnie.
          </p>
          <p className="link-container">
            <Link to="/contact">Zapytaj nas o szczegóły</Link>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default organisation_item
